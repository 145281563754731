(function() {
  'use strict';

  angular
    .module('investorlist', [
      'ngAnimate',
      'ngTouch',
      'ngSanitize',
      'ngResource',
      'ui.router',
      'ngDialog',
      'angular-inview',
      'headroom'
    ]);
})();

(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('stateWatcher', stateWatcher)

    /** @ngInject */
    function stateWatcher($rootScope, $state, $timeout, _) {
      var directive = {
        restrict: 'A',
        link: linkFn,
        require: ['?dropdownButton'],
        controller: StateWatcherController,
        controllerAs: 'stateWatcher',
        bindToController: {
         config: '=stateWatcher'
        }
      };

      return directive;

      function linkFn(scope, element, attributes, controllers) {
        var dropdown = controllers[0]
        scope.stateWatcher.dropdown = dropdown;
      }

      /** @ngInject */
      function StateWatcherController($scope) {
        var vm = this;

        vm.syncState = function(params) {
          var option = {}
          option[vm.config.value] = params[vm.config.param]
          var foundOption = _.find(vm.config.options, option)
          var name = foundOption ? foundOption.name : undefined
          $timeout(function() {
            $scope.stateWatcher.dropdown.setCurrentSelection(name)
          })
        }

        vm.syncState($state.params)

        var stateChangeWatcher = $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams) {
          vm.syncState(toParams)
        });
        $scope.$on('$destroy', function() { stateChangeWatcher() })

        $scope.$watch('stateWatcher.dropdown.newSelection', function(selection) {
          var option = {}
          option[vm.config.label] = selection
          var foundOption = _.find(vm.config.options, option)

          if(foundOption) {
            var opts = {}
            opts[vm.config.param] = foundOption[vm.config.value]
            $state.go(vm.config.state, opts)
          }
        })

      }
    }
})();
(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('poweredBy', poweredBy)

  /** @ngInject */
  function poweredBy() {
    var directive = {
      restrict: 'E',
      scope: {},
      templateUrl: 'app/components/poweredBy/poweredBy.html',
      controller: PoweredByController
    };

    return directive;

    /** @ngInject */
    function PoweredByController($window, $element) {
      var page = angular.element($window)

      page.on('scroll', function() {
        if (page.scrollTop() > 50) {
          $element.addClass('-visible');
        } else {
          $element.removeClass('-visible');
        }
      });
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('navBar', navBar);

  /** @ngInject */
  function navBar() {
    var directive = {
      restrict: 'E',
      templateUrl: 'app/components/navBar/navBar.html',
      scope: {},
      controller: NavBarController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;

    /** @ngInject */
    function NavBarController(Modal) {
      var vm = this;

      vm.about = function() {
        Modal('app/modals/aboutModal.html', {}, 'about-modal')
      }

    }
  }

})();

(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('locationFilter', locationFilter)

  /** @ngInject */
  function locationFilter() {
    var directive = {
      restrict: 'E',
      scope: {},
      templateUrl: 'app/components/locationFilter/locationFilter.html',
      controller: LocationFilterController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;

    /** @ngInject */
    function LocationFilterController(InvestorCountries) {
      var vm = this;

      vm.config = {
        param: 'location',
        value: 'slug',
        label: 'name',
        state: 'home.list',
        title: 'Location',
        options: InvestorCountries.filters(),
        preSelection: 'Everywhere'
      }
    }
  }
})();
(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('loadingSpinner', loadingSpinner)

  /** @ngInject */
  function loadingSpinner() {
    var directive = {
      restrict: 'E',
      templateUrl: 'app/components/loadingSpinner/loadingSpinner.html'
    };

    return directive;
  }
})();
(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('investorCard', investorCard)

  /** @ngInject */
  function investorCard(InvestorModal, $state) {
    var directive = {
      restrict: 'E',
      scope: {},
      templateUrl: 'app/components/investorCard/investorCard.html',
      controller: investorCardController,
      controllerAs: 'vm',
      bindToController: {
        investor: '<'
      }
    };

    return directive;

    /** @ngInject */
    function investorCardController($timeout) {
      var vm = this;

      vm.launch = function() {
        InvestorModal(vm.investor, $state.current, $state.params);
      }

      vm.bg = function() {
        var imageUrl
        if(vm.imageState === 'loaded') {
          imageUrl = vm.investor.logo
        } else {
          imageUrl = "/assets/images/placeholder.jpg"
        }

        return { backgroundImage: "url(" + imageUrl + ")" }
      }

      vm.$onInit = function() {
        if(!vm.investor.logo) return
        var image = new Image()
        image.onload = $timeout(function() { vm.imageState = 'loaded' })
        image.onerror = function() {
          vm.investor.logo = "/assets/images/placeholder.jpg"
          vm.imageState = 'failed'
        }
        image.src = vm.investor.logo
      }

      vm.flagCode = vm.investor.country.split(",")[0].trim();

    }
  }
})();

(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('inputSearchField', inputSearchField)

  /** @ngInject */
  function inputSearchField() {
    var directive = {
      restrict: 'E',
      scope: {},
      templateUrl: 'app/components/inputSearchField/inputSearchField.html',
      link: linkFn,
      controller: InputSearchFieldController,
      controllerAs: 'vm',
      bindToController: {
        searchState: '@',
        param: '@',
        placeholder: '@'
      }
    };

    return directive;

    function linkFn() {
      // Mousetrap(element.get(0)).bind('enter', function(e) {
      //   e.preventDefault();
      //   var obj = {}
      //   obj[vm.param] = vm.query
      //   $state.go(vm.searchState, obj)
      // })
    }

    /** @ngInject */
    function InputSearchFieldController() {
    }
  }
})();
(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('dropdownValidator', dropdownValidator)

    /** @ngInject */
    function dropdownValidator() {
      var directive = {
        restrict: 'A',
        require: {
          ngModel: 'ngModel'
        },
        controller: DropdownValidatorController,
        controllerAs: 'vm',
        bindToController: true,
        scope: {}
      };

      return directive;

      /** @ngInject */
      function DropdownValidatorController() {
        var vm = this;

        vm.$onInit = function() {
          vm.ngModel.$validators.selection = function(modelValue) {
            if(modelValue == 'Select one') return false;
            return true
          };
        }

      }
    }
})();
(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('dropdownButton', dropdownButton)
    .directive('dropdownButtonChoiceList', dropdownButtonChoiceList)

  /** @ngInject */
  function dropdownButton($sce) {
    var directive = {
      restrict: 'E',
      scope: {},  
      templateUrl: 'app/components/dropdownButton/dropdownButton.html',
      transclude: true,
      link: linkFn,
      controller: DropdownButtonController,
      bindToController: {
        text: '&',
        icon: '@',
        preSelection: '='
      },
      controllerAs: 'vm'
    };

    return directive;

    function linkFn(scope, element, attrs, vm) {
      vm.default = vm.preSelection

      vm.currentSelection = vm.preSelection

      vm.textCopy = vm.text()

      vm.htmlText = function(text) {
        return $sce.trustAsHtml(text);
      }

      vm.dropdownLabel = vm.htmlText(vm.textCopy)

      vm.direction = 'bottom-right';
    }

    /** @ngInject */
    function DropdownButtonController($element) {
      var vm = this;

      vm.open = false;

      vm.element = $element

      vm.setCurrentSelection = function(optionName) {
        vm.newSelection = (optionName || vm.default)
        vm.textCopy = vm.textCopy.replace(vm.currentSelection, vm.newSelection)
        vm.dropdownLabel = vm.htmlText(vm.textCopy)
        vm.currentSelection = vm.newSelection
        vm.preSelection = vm.currentSelection
      }
    }
  }

  /** @ngInject */
  function dropdownButtonChoiceList($compile) {
    var directive = {
      restrict: 'E',
      scope: {},
      link: linkFn,
      require: ['dropdownButtonChoiceList', '^dropdownButton'],
      replace: true,
      controller: DropdownButtonChoiceListController,
      bindToController: {
        options: '='
      },
      controllerAs: 'vm'
    };

    return directive;

    function linkFn(scope, element, attrs, controllers) {
      var vm = controllers[0]
      var parent = controllers[1]

      vm.options.forEach(function(option) {
        vm.notifyCurrentSelection = function(optionName) {
          return parent.setCurrentSelection(optionName)
        }
        var item = $compile("<li><a ng-click='vm.notifyCurrentSelection(" + '"' + option.name + '"' + ")'>" + option.name + "</a></li>")(scope)
        parent.element.find('.list-items').append(item)
      })
    }

    /** @ngInject */
    function DropdownButtonChoiceListController() {
    }
  }

})();

(function() {
  var contactButton = {
    bindings: {
      investor: '<'
    },
    controller: ContactButtonController,
    controllerAs: 'vm',
    templateUrl: 'app/components/contactButton/contactButton.html'
  }

  /** @ngInject */
  function  ContactButtonController() {
    var vm = this;
    vm.$onInit = pickBestContact;

    function pickBestContact() {
      if(vm.investor.email) {
        vm.bestContact = 'email';
      } else if(vm.investor.screenName) {
        vm.bestContact = 'twitter';
      } else if(vm.investor.linkedin) {
        vm.bestContact = 'linkedin';
      }
    }
  }

  angular.module('investorlist').component('contactButton', contactButton);
})();
(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('clearSearch', clearSearch)

  /** @ngInject */
  function clearSearch() {
    var directive = {
      restrict: 'E',
      scope: {},
      templateUrl: 'app/components/clearSearch/clearSearch.html',
      controller: ClearSearchController,
      controllerAs: 'vm',
      bindToController: {
        active: '=',
        query: '='
      }
    };

    return directive;

    /** @ngInject */
    function ClearSearchController($state) {
      var vm = this;

      vm.clear = function() {
        vm.query = '';
        $state.go('home.list', { query: undefined }, { notify: false })
      }

    }
  }
})();
(function() {
  'use strict';

  angular
    .module('investorlist')
    .directive('categoryFilter', categoryFilter)

  /** @ngInject */
  function categoryFilter(CATEGORY_OPTIONS) {
    var directive = {
      restrict: 'E',
      scope: {},
      templateUrl: 'app/components/categoryFilter/categoryFilter.html',
      controller: CategoryFilterController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;

    /** @ngInject */
    function CategoryFilterController() {
      var vm = this;

      vm.config = {
        param: 'id',
        value: 'slug',
        label: 'name',
        state: 'home.list',
        title: 'Category',
        options: CATEGORY_OPTIONS,
        preSelection: 'All'
      }

    }
  }
})();
(function() {
  var addInvestorForm = {
    controller: AddInvestorFormController,
    controllerAs: 'vm',
    templateUrl: 'app/components/addInvestorForm/addInvestorForm.html'
  }

  /** @ngInject */
  function  AddInvestorFormController(InvestorAdder, ngDialog, CATEGORY_OPTIONS) {
    var vm = this;

    vm.$onInit = function() {
      var categories = CATEGORY_OPTIONS;
      categories.shift();
      vm.categories = categories;
      vm.investor = { category: 'Select one' };
    }

    vm.add = function($event) {
      $event.preventDefault();
      vm.disabled = true;
      InvestorAdder(vm.investor).then(function() {
        ngDialog.closeAll();
      })
    }
  }

  angular.module('investorlist').component('addInvestorForm', addInvestorForm);
})();
(function() {
  var addCard = {
    controller: AddCardController,
    template: '<ng-transclude></ng-transclude>',
    transclude: true
  }

  /** @ngInject */
  function  AddCardController($element, Modal) {
    $element.on('click', function() {
      Modal('app/modals/addModal.html', {}, 'add-modal')
    })
  }

  angular.module('investorlist').component('addCard', addCard);
})();
(function() {
  'use strict';

  function Twitterify($q) {
    return function(dataPromise) {
      var deferred = $q.defer();

      dataPromise.then(function(investors) {
        investors.forEach(function(investor) {
          if(!investor.logo && investor.twitter) {
            investor.screenName = investor.twitter.replace('@', '');
            investor.logo = 'https://avatars.io/twitter/' + investor.screenName
          }
        })

        deferred.resolve(investors)
      });

      return deferred.promise;
    }
  }

  angular.module('investorlist')
    .factory('Twitterify', Twitterify);
})();

(function() {
  'use strict';

  function slugify(_) {
    return function(entities) {
      entities.forEach(function(entity) {
        entity.slug = _.kebabCase(entity.name);
      })

      return _.uniqBy(entities, 'slug');
    }
  }

  angular.module('investorlist').factory('slugify', slugify);
})();
(function() {
  'use strict';

  function SearchFactory(_, lunr) {
    var Search = function() {
      this.index = lunr(function () {
        this.field('name', {boost: 10})
        this.field('description')
        this.ref('slug')
      })
    }

    Search.prototype.add = function(investors) {
      var self = this;

      investors.forEach(function(investor) {
        var searchEntry = _(investor).pick('name', 'description', 'slug').value();
        self.index.add(searchEntry);
      })
    };

    Search.prototype.for = function(investors, query) {
      var results = this.index.search(query);
      return _.intersectionBy(investors, results, function(match) {
        return match.ref || match.slug;
      })
    }

    return new Search();
  }

  angular.module('investorlist').factory('Search', SearchFactory);
})();

(function() {
  'use strict';

  function Modal(ngDialog, $state) {
    return function launchModal(template, data, modalClass, state, params) {
      ngDialog.closeAll();
      var dialog = ngDialog.open({
        template: template,
        className: ['ngdialog-theme-peg', modalClass].join(' '),
        data: data,
        controller: ModalController,
        controllerAs: 'vm'
      });

      /** @ngInject */
      function ModalController($scope) {
        $scope.vm = $scope.ngDialogData;
      }

      dialog.closePromise.then(function() {
        if(state) {
          $state.go(state, params, { inherit: false, notify: false })
        } else {
          $state.go('home.list', {}, { inherit: false })
        }
      })
    }
  }

  angular.module('investorlist').factory('Modal', Modal);
})();
(function() {
  'use strict';

  // NOTE: This code isn't used in the app; CSV is more efficient for transmission
  function jsonFetcher($http, $q, _) {
    return function(url, investorType) {
      var deferred = $q.defer();

      $http.get(url).then(function(res) {
        var list = res.data.feed.entry.map(formatRow);
        correctWebsiteFormat(list);
        var namedList = ensureNames(list);

        deferred.resolve(namedList)
      })
      return deferred.promise;

      function formatRow(r) {
        var row = _(r).pickBy(function(val, key) {
          return key.indexOf('gsx$') > -1;
        }).mapKeys(function(val, key) {
          return key.replace('gsx$', '');
        }).mapValues(function(val) {
          return val.$t;
        }).value();

        row.type = investorType;
        return row;
      }

      function correctWebsiteFormat(list) {
        list.forEach(function(row) {
          if(row.website && row.website.length && row.website.substr(0, 4) !== 'http') {
            row.website = 'http://' + row.website;
          }
        })
      }

      function ensureNames(list) {
        list.forEach(function(row) {
          if(!row.name && row.firstName) row.name = [row.firstName, row.lastName].join(' ');
        })

        return list.filter(function(row) {
          return row.name && row.name.trim().length;
        })
      }
    }
  }

  angular.module('investorlist').factory('jsonFetcher', jsonFetcher);
})();
(function() {
  'use strict';

  function InvestorModal(Modal) {
    return function launchInvestorModal(investor, state, params) {
      Modal('app/modals/investorModal.html', { investor: investor }, 'investor-modal', state, params);
    }
  }

  angular.module('investorlist').factory('InvestorModal', InvestorModal);
})();
(function() {
  'use strict';

  angular.module('investorlist')
    .factory('InvestorCountries', function(COUNTRIES, Accelerators, Angels, _) {
      var InvestorCountries = function() {
        this.mappings = COUNTRIES
        this.investorTypes = [Accelerators, Angels]
        this.investorCountries = this.fetchInvestorCountries()
      };

      InvestorCountries.prototype.fetchInvestorCountries = function() {
        var self = this;
        var uniqueCountries = new Set();
        self.investorTypes.forEach(function(type) {
          type.then(function(t) {
            t.forEach(function(investor){
              if(investor.country.length) {
                var multipleCountries = investor.country.split(',')
                var processedCountries = multipleCountries.map(function(code) { return code.trim() })
                processedCountries.forEach(function(code) {
                  var countryName = self.getName(code)
                  var countrySlug = _.kebabCase(countryName)
                  uniqueCountries[countrySlug] = countryName
                })
              }
            })
          })
        })
        return uniqueCountries;
      }

      InvestorCountries.prototype.getName = function(code) {
        if (this.mappings.hasOwnProperty(code)) {
          return this.mappings[code];
        } else {
          return code;
        }
      }

      InvestorCountries.prototype.getCode = function(slug) {
        var self = this;
        var name = self.investorCountries[slug]
        for(var code in self.mappings) {
          if(self.mappings.hasOwnProperty(code)) {
            if(self.mappings[code] === name )
              return code;
          }
        }
      }

      InvestorCountries.prototype.filters = function() {
        var self = this;
        var countryList = []
        
        Object.keys(self.investorCountries).forEach(function(countrySlug) {
          var obj = {}
          obj.name = self.investorCountries[countrySlug]
          obj.slug = countrySlug
          if(obj.name.trim().length) countryList.push(obj)
        })

        var sorted =  _.sortBy(countryList, function(country) {
          if(country.name === 'United Kingdom') return '1';
          return country.name
        })

        sorted.unshift({name: "Everywhere", slug: ""})
        return sorted
      }

      return new InvestorCountries;
    })
})();
(function() {
  'use strict';

  function InvestorAdder($http, _, $q, jQuery) {
    var propMapping = {
      name: "entry.785785350",
      email: "entry.978586060",
      website: "entry.806299784",
      category: "entry.55274607",
      location: "entry.1569624516",
      twitter: "entry.2005969111",
      linkedin: "entry.1764794403",
      description: "entry.1295775900"
    };

    return function addInvestor(investorDetails) {
      var deferred = $q.defer();

      var investorForForm = _.mapKeys(investorDetails, function(val, key) {
        return propMapping[key];
      })

      jQuery.ajax({
        url: "https://thingproxy.freeboard.io/fetch/https://docs.google.com/forms/d/1LW3sheoHhvZ5gdEThlC8QJrY3Xw3dWctMRFHHgt2E4A/formResponse",
        data: investorForForm,
        type: "POST",
        dataType: "xml",
        complete: function() {
          deferred.resolve(true);
        }
      })

      return deferred.promise;
    }
  }

  angular.module('investorlist').factory('InvestorAdder', InvestorAdder);
})();
(function() {
  'use strict';
  var baseUrl = "/data/";

  function Accelerators(csvFetcher, Twitterify) {
    var id = "accelerators.csv";
    var headers = ['name', 'description', 'location', 'region', 'website', 'ceo', 'email', 'logo', 'twitter', 'country'];

    return Twitterify(csvFetcher(baseUrl + id, headers, 'Accelerator'))
  }

  function VCs(csvFetcher, Twitterify) {
    var id = "vcs.csv";
    var headers = ['name', 'description', 'email', 'location', 'website', 'logo', 'twitter', 'country', 'linkedin'];

    return Twitterify(csvFetcher(baseUrl + id, headers, 'VC'));
  }

  function Angels(csvFetcher, Twitterify) {
    var id = "angels.csv";
    var headers = ['firstName', 'lastName', 'website', 'location', 'description', 'website', 'twitter', 'country', 'logo'];

    return Twitterify(csvFetcher(baseUrl + id, headers, 'Angel'));
  }

  function Crowdfunding(csvFetcher, Twitterify) {
    var id = "crowdfunding.csv";
    var headers = ['name', 'description', 'location', 'website', 'twitter', 'country'];

    return Twitterify(csvFetcher(baseUrl + id, headers, 'Crowdfunding'));
  }

  function Companybuilders(csvFetcher, Twitterify) {
    var id = "companybuilders.csv";
    var headers = ['name', 'description', 'location', 'website', 'twitter', 'country'];

    return Twitterify(csvFetcher(baseUrl + id, headers, 'Company Builder'));
  }

  function Angelgroups(csvFetcher, Twitterify) {
    var id = "angelgroups.csv";
    var headers = ['name', 'description', 'location', 'website', 'twitter', 'country'];

    return Twitterify(csvFetcher(baseUrl + id, headers, 'Angel Group'));
  }

  function Loans(csvFetcher, Twitterify) {
    var id = "loans.csv";
    var headers = ['name', 'description', 'location', 'website', 'twitter', 'country'];

    return Twitterify(csvFetcher(baseUrl + id, headers, 'Loans'));
  }

  angular.module('investorlist')
    .factory('Accelerators', Accelerators)
    .factory('VCs', VCs)
    .factory('Angels', Angels)
    .factory('Crowdfunding', Crowdfunding)
    .factory('Companybuilders', Companybuilders)
    .factory('Angelgroups', Angelgroups)
    .factory('Loans', Loans)
    .constant('investorTypes', {
      vcs: 'VC',
      angels: 'Angel',
      accelerators: 'Accelerator',
      crowdfunding: 'Crowdfunding',
      companybuilders: 'Company Builder',
      angelgroups: 'Angel Group',
      loans: 'Loans'
    })
})();

(function() {
  'use strict';

  function csvFetcher(Papa, $q, _) {
    return function(url, headers, investorType) {
      var deferred = $q.defer();

      Papa.parse(url, {
        download: true,
        complete: function(parsed) {
          var list = replaceHeader(parsed.data);
          correctWebsiteFormat(list);
          var namedList = ensureNames(list);
          deferred.resolve(namedList)
        }
      })

      return deferred.promise;

      function replaceHeader(data) {
        var withoutHeaderRow = data.slice(1, data.length);

        return withoutHeaderRow.map(function(row) {
          var headedRow = _(headers).zip(row).fromPairs().value();
          headedRow.type = investorType || '';

          return headedRow;
        })
      }

      function correctWebsiteFormat(list) {
        list.forEach(function(row) {
          if(row.website.length && row.website.substr(0, 4) !== 'http') {
            row.website = 'http://' + row.website;
          }
        })
      }

      function ensureNames(list) {
        list.forEach(function(row) {
          if(!row.name && row.firstName) row.name = [row.firstName, row.lastName].join(' ');
        })

        return list.filter(function(row) {
          return row.name && row.name.trim().length;
        })
      }
    }
  }

  angular.module('investorlist').factory('csvFetcher', csvFetcher);
})();
(function() {
  'use strict';

  angular
    .module('investorlist')
    .controller('MainController', MainController);

  /** @ngInject */
  function MainController(Accelerators, Angels, Angelgroups, Companybuilders, Crowdfunding, Loans, VCs, slugify, $state, Search, $scope) {
    var vm = this;

    vm.allInvestors = slugify([].concat(Accelerators, Angels, Angelgroups, Companybuilders, Crowdfunding, Loans, VCs));
    Search.add(vm.allInvestors);
    
    vm.searchActive = false;
    vm.query = $state.params.query;


    $scope.$watch('main.query', function(newVal) {
      if(!newVal) vm.search();
    })

    vm.search = function() {
      if(vm.query && vm.query !== $state.params.query) $state.go('home.list', { query: vm.query });

      if(vm.query) {
        vm.searchActive = true;
        vm.investors = Search.for(vm.allInvestors, vm.query);
      } else {
        vm.searchActive = false;
        vm.investors = vm.allInvestors; 
      }
    }

    vm.search();
  }
})();

(function() {
  'use strict';

  angular
    .module('investorlist')
    .controller('ListController', ListController);

  /** @ngInject */
  function ListController($state, $scope, InvestorModal, investorTypes, ngDialog, _, InvestorCountryList) {
    var vm = this;
    vm.page = 1;

    $scope.main.query = $state.params.query;

    $scope.$watch('results.length', function(value) {
      if(typeof value == undefined) return;
      $scope.main.resultCount = value
    })

    vm.loadMoreInvestors = function() {
      vm.page += 1
    }

    vm.categoryfilter = investorTypes[$state.params.id];
    vm.locationFilter = InvestorCountryList.getCode([$state.params.location]);

    var current = _.find($scope.main.investors, { slug: $state.params.id });
    if(current) {
      InvestorModal(current)
    } else {
      ngDialog.closeAll()
    }
  }
})();
(function() {
  'use strict';

  angular
    .module('investorlist')
    .filter('truncateCountries', TruncateCountriesFilter);

  /** @ngInject */
  function TruncateCountriesFilter() {
    function filterFn(input) {
      if(!input) return;
      var countries = input.split(',').map(function(c) { return c.trim() })
      var firstCountry = countries.shift()

      return firstCountry + (countries.length ? ' + ' + countries.length + ' more' : '');
    }

    return filterFn;
  }
})();
(function() {
  'use strict';

  angular
    .module('investorlist')
    .run(runBlock);

  /** @ngInject */
  function runBlock($log) {
    $log.debug('runBlock end');
  }

})();

(function() {
  'use strict';

  angular
    .module('investorlist')
    .config(routerConfig);

  /** @ngInject */
  function routerConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
      .state('home', {
        abstract: true,
        templateUrl: 'app/main/main.html',
        controller: 'MainController',
        controllerAs: 'main',
        resolve: {
          Accelerators: 'Accelerators',
          Angels: 'Angels',
          Angelgroups: 'Angelgroups',
          Companybuilders: 'Companybuilders',
          Crowdfunding: 'Crowdfunding',
          Loans: 'Loans',
          VCs: 'VCs'
        }
      })
      .state('home.list', {
        url: '/:id?location=:&query=:',
        templateUrl: 'app/list/list.html',
        controller: 'ListController',
        controllerAs: 'vm',
        resolve: {
          InvestorCountryList: function(InvestorCountries, $stateParams, $q){
            var deferred = $q.defer();
            deferred.resolve(InvestorCountries);
            return deferred.promise;
          }
        }
      });

    $urlRouterProvider.otherwise('/');
    $locationProvider.html5Mode(true);
  }

})();
/* global moment:false, Papa:false, lunr:false, _:false, jQuery:false */
(function() {
  'use strict';

  angular
    .module('investorlist')
    .constant('moment', moment)
    .constant('Papa', Papa)
    .constant('lunr', lunr)
    .constant('jQuery', jQuery)
    .constant('_', _)
    .constant('COUNTRIES', {
      'AF' : 'Afghanistan',
      'AX' : 'Aland Islands',
      'AL' : 'Albania',
      'DZ' : 'Algeria',
      'AS' : 'American Samoa',
      'AD' : 'Andorra',
      'AO' : 'Angola',
      'AI' : 'Anguilla',
      'AQ' : 'Antarctica',
      'AG' : 'Antigua And Barbuda',
      'AR' : 'Argentina',
      'AM' : 'Armenia',
      'AW' : 'Aruba',
      'AU' : 'Australia',
      'AT' : 'Austria',
      'AZ' : 'Azerbaijan',
      'BS' : 'Bahamas',
      'BH' : 'Bahrain',
      'BD' : 'Bangladesh',
      'BB' : 'Barbados',
      'BY' : 'Belarus',
      'BE' : 'Belgium',
      'BZ' : 'Belize',
      'BJ' : 'Benin',
      'BM' : 'Bermuda',
      'BT' : 'Bhutan',
      'BO' : 'Bolivia',
      'BA' : 'Bosnia And Herzegovina',
      'BW' : 'Botswana',
      'BV' : 'Bouvet Island',
      'BR' : 'Brazil',
      'IO' : 'British Indian Ocean Territory',
      'BN' : 'Brunei Darussalam',
      'BG' : 'Bulgaria',
      'BF' : 'Burkina Faso',
      'BI' : 'Burundi',
      'KH' : 'Cambodia',
      'CM' : 'Cameroon',
      'CA' : 'Canada',
      'CV' : 'Cape Verde',
      'KY' : 'Cayman Islands',
      'CF' : 'Central African Republic',
      'TD' : 'Chad',
      'CL' : 'Chile',
      'CN' : 'China',
      'CX' : 'Christmas Island',
      'CC' : 'Cocos (Keeling) Islands',
      'CO' : 'Colombia',
      'KM' : 'Comoros',
      'CG' : 'Congo',
      'CD' : 'Congo, Democratic Republic',
      'CK' : 'Cook Islands',
      'CR' : 'Costa Rica',
      'CI' : 'Cote D\'Ivoire',
      'HR' : 'Croatia',
      'CU' : 'Cuba',
      'CY' : 'Cyprus',
      'CZ' : 'Czech Republic',
      'DK' : 'Denmark',
      'DJ' : 'Djibouti',
      'DM' : 'Dominica',
      'DO' : 'Dominican Republic',
      'EC' : 'Ecuador',
      'EG' : 'Egypt',
      'SV' : 'El Salvador',
      'GQ' : 'Equatorial Guinea',
      'ER' : 'Eritrea',
      'EE' : 'Estonia',
      'ET' : 'Ethiopia',
      'FK' : 'Falkland Islands (Malvinas)',
      'FO' : 'Faroe Islands',
      'FJ' : 'Fiji',
      'FI' : 'Finland',
      'FR' : 'France',
      'GF' : 'French Guiana',
      'PF' : 'French Polynesia',
      'TF' : 'French Southern Territories',
      'GA' : 'Gabon',
      'GM' : 'Gambia',
      'GE' : 'Georgia',
      'DE' : 'Germany',
      'GH' : 'Ghana',
      'GI' : 'Gibraltar',
      'GR' : 'Greece',
      'GL' : 'Greenland',
      'GD' : 'Grenada',
      'GP' : 'Guadeloupe',
      'GU' : 'Guam',
      'GT' : 'Guatemala',
      'GG' : 'Guernsey',
      'GN' : 'Guinea',
      'GW' : 'Guinea-Bissau',
      'GY' : 'Guyana',
      'HT' : 'Haiti',
      'HM' : 'Heard Island & Mcdonald Islands',
      'VA' : 'Holy See (Vatican City State)',
      'HN' : 'Honduras',
      'HK' : 'Hong Kong',
      'HU' : 'Hungary',
      'IS' : 'Iceland',
      'IN' : 'India',
      'ID' : 'Indonesia',
      'IR' : 'Iran, Islamic Republic Of',
      'IQ' : 'Iraq',
      'IE' : 'Ireland',
      'IM' : 'Isle Of Man',
      'IL' : 'Israel',
      'IT' : 'Italy',
      'JM' : 'Jamaica',
      'JP' : 'Japan',
      'JE' : 'Jersey',
      'JO' : 'Jordan',
      'KZ' : 'Kazakhstan',
      'KE' : 'Kenya',
      'KI' : 'Kiribati',
      'KR' : 'Korea',
      'KW' : 'Kuwait',
      'KG' : 'Kyrgyzstan',
      'LA' : 'Lao People\'s Democratic Republic',
      'LV' : 'Latvia',
      'LB' : 'Lebanon',
      'LS' : 'Lesotho',
      'LR' : 'Liberia',
      'LY' : 'Libyan Arab Jamahiriya',
      'LI' : 'Liechtenstein',
      'LT' : 'Lithuania',
      'LU' : 'Luxembourg',
      'MO' : 'Macao',
      'MK' : 'Macedonia',
      'MG' : 'Madagascar',
      'MW' : 'Malawi',
      'MY' : 'Malaysia',
      'MV' : 'Maldives',
      'ML' : 'Mali',
      'MT' : 'Malta',
      'MH' : 'Marshall Islands',
      'MQ' : 'Martinique',
      'MR' : 'Mauritania',
      'MU' : 'Mauritius',
      'YT' : 'Mayotte',
      'MX' : 'Mexico',
      'FM' : 'Micronesia, Federated States Of',
      'MD' : 'Moldova',
      'MC' : 'Monaco',
      'MN' : 'Mongolia',
      'ME' : 'Montenegro',
      'MS' : 'Montserrat',
      'MA' : 'Morocco',
      'MZ' : 'Mozambique',
      'MM' : 'Myanmar',
      'NA' : 'Namibia',
      'NR' : 'Nauru',
      'NP' : 'Nepal',
      'NL' : 'Netherlands',
      'AN' : 'Netherlands Antilles',
      'NC' : 'New Caledonia',
      'NZ' : 'New Zealand',
      'NI' : 'Nicaragua',
      'NE' : 'Niger',
      'NG' : 'Nigeria',
      'NU' : 'Niue',
      'NF' : 'Norfolk Island',
      'MP' : 'Northern Mariana Islands',
      'NO' : 'Norway',
      'OM' : 'Oman',
      'PK' : 'Pakistan',
      'PW' : 'Palau',
      'PS' : 'Palestinian Territory, Occupied',
      'PA' : 'Panama',
      'PG' : 'Papua New Guinea',
      'PY' : 'Paraguay',
      'PE' : 'Peru',
      'PH' : 'Philippines',
      'PN' : 'Pitcairn',
      'PL' : 'Poland',
      'PT' : 'Portugal',
      'PR' : 'Puerto Rico',
      'QA' : 'Qatar',
      'RE' : 'Reunion',
      'RO' : 'Romania',
      'RU' : 'Russian Federation',
      'RW' : 'Rwanda',
      'BL' : 'Saint Barthelemy',
      'SH' : 'Saint Helena',
      'KN' : 'Saint Kitts And Nevis',
      'LC' : 'Saint Lucia',
      'MF' : 'Saint Martin',
      'PM' : 'Saint Pierre And Miquelon',
      'VC' : 'Saint Vincent And Grenadines',
      'WS' : 'Samoa',
      'SM' : 'San Marino',
      'ST' : 'Sao Tome And Principe',
      'SA' : 'Saudi Arabia',
      'SN' : 'Senegal',
      'RS' : 'Serbia',
      'SC' : 'Seychelles',
      'SL' : 'Sierra Leone',
      'SG' : 'Singapore',
      'SK' : 'Slovakia',
      'SI' : 'Slovenia',
      'SB' : 'Solomon Islands',
      'SO' : 'Somalia',
      'ZA' : 'South Africa',
      'GS' : 'South Georgia And Sandwich Isl.',
      'ES' : 'Spain',
      'LK' : 'Sri Lanka',
      'SD' : 'Sudan',
      'SR' : 'Suriname',
      'SJ' : 'Svalbard And Jan Mayen',
      'SZ' : 'Swaziland',
      'SE' : 'Sweden',
      'CH' : 'Switzerland',
      'SY' : 'Syrian Arab Republic',
      'TW' : 'Taiwan',
      'TJ' : 'Tajikistan',
      'TZ' : 'Tanzania',
      'TH' : 'Thailand',
      'TL' : 'Timor-Leste',
      'TG' : 'Togo',
      'TK' : 'Tokelau',
      'TO' : 'Tonga',
      'TT' : 'Trinidad And Tobago',
      'TN' : 'Tunisia',
      'TR' : 'Turkey',
      'TM' : 'Turkmenistan',
      'TC' : 'Turks And Caicos Islands',
      'TV' : 'Tuvalu',
      'UG' : 'Uganda',
      'UA' : 'Ukraine',
      'AE' : 'United Arab Emirates',
      'UK' : 'United Kingdom',
      'US' : 'United States',
      'UM' : 'United States Outlying Islands',
      'UY' : 'Uruguay',
      'UZ' : 'Uzbekistan',
      'VU' : 'Vanuatu',
      'VE' : 'Venezuela',
      'VN' : 'Vietnam',
      'VG' : 'Virgin Islands, British',
      'VI' : 'Virgin Islands, U.S.',
      'WF' : 'Wallis And Futuna',
      'EH' : 'Western Sahara',
      'YE' : 'Yemen',
      'ZM' : 'Zambia',
      'ZW' : 'Zimbabwe'
    })
    .constant('CATEGORY_OPTIONS', [
      {name: "All", slug:''},
      {name: "Accelerators", slug: "accelerators"},
      {name: "Angels", slug: "angels"},
      {name: "Angel Groups", slug: "angelgroups"},
      {name: "Company Builders", slug: "companybuilders"},
      {name: "Crowdfunding", slug: "crowdfunding"},
      {name: "Loans", slug: "loans"},
      {name: "VCs", slug: "vcs"}
    ]);
})();

(function() {
  'use strict';

  angular
    .module('investorlist')
    .config(config);

  /** @ngInject */
  function config($logProvider) {
    // Enable log
    $logProvider.debugEnabled(true);

  }

})();

angular.module("investorlist").run(["$templateCache", function($templateCache) {$templateCache.put("app/list/list.html","<section class=\"card-list -small-cards\" style=\"margin-top: 40px;\"><investor-card investor=\"::investor\" launch=\"vm.launch\" ng-repeat=\'investor in main.filteredInvestors = ( main.investors | orderBy:(main.searchActive ? true : \"name\") | filter:{type:vm.categoryfilter, country:vm.locationFilter} ) | limitTo:(vm.page * 30)\'></investor-card><div in-view=\"$inview && vm.loadMoreInvestors()\"></div><div class=\"card -placeholder -medium -margin-auto -more-padding\" ng-if=\"!main.filteredInvestors.length\"><h3>Hmm, we couldn\'t find anyone</h3><p>Unfortunately no investors matched your search. You might want to <a ui-sref=\"home.list({})\" ui-sref-opts=\"{ inherit: false }\">clear your filters.</a></p></div></section>");
$templateCache.put("app/main/main.html","<nav-bar></nav-bar><header class=\"raised-area -centered\"><container><section class=\"search-container\"><h1 class=\"total\">Investor List</h1><h5 class=\"strapline\" ng-switch=\"main.filteredInvestors.length === main.allInvestors.length\"><span ng-switch-when=\"true\">A crowdsourced list of {{ main.allInvestors.length }}<ng-pluralize count=\"main.allInvestors.length\" when=\"{ \'1\': \' investor \', \'other\': \' investors \' }\"></ng-pluralize>for your searching pleasure!</span> <span ng-switch-when=\"false\">Your search matches {{main.filteredInvestors.length}}<ng-pluralize count=\"main.filteredInvestors.length\" when=\"{ \'1\': \' result \', \'other\': \' results \'}\"></ng-pluralize></span></h5><section class=\"search-area\"><form ng-submit=\"main.search()\"><input type=\"text\" ng-model=\"main.query\" placeholder=\"Search\"><clear-search active=\"main.searchActive\" query=\"main.query\"></clear-search></form><section class=\"filter-area\"><category-filter></category-filter><location-filter></location-filter></section></section></section></container><powered-by></powered-by></header><main container=\"\"><ui-view></ui-view></main>");
$templateCache.put("app/modals/aboutModal.html","<div class=\"inner\"><h1>Hello</h1><p>We\'re the founders of <a href=\"https://peg.co\">Peg</a>.</p><p>As a tech startup currently raising investment, we were immensely grateful when we came across a crowdsourced list of investors that <a href=\"https://twitter.com/robmoff\">Rob Moffat</a> shared on Twitter.</p><p>We thought we\'d share the love and give back to the community by building a search engine for the data which will hopefully be helpful to other startups searching for funding! :)</p><div class=\"actions\"><a href=\"mailto:me@alexpeattie.com\"><button class=\"raised-button -outline\">Say hello</button></a> <a href=\"https://peg.co\" target=\"_blank\"><button class=\"raised-button -cta\">Check Peg out!</button></a></div></div>");
$templateCache.put("app/modals/addModal.html","<section class=\"inner\"><h1>Add an investor</h1><add-investor-form></add-investor-form></section>");
$templateCache.put("app/modals/investorModal.html","<article class=\"investor-pic\"><span class=\"pic\" ng-style=\':: { backgroundImage: \"url(\" + (vm.investor.logo || \"/assets/images/placeholder.jpg\") + \")\" }\'></span></article><div class=\"inner\"><h1>{{:: vm.investor.name }}</h1><p class=\"about\">{{:: vm.investor.type }}<divider ng-show=\"::vm.investor.location.trim().length\"></divider>{{:: vm.investor.location }}</p><contact-button investor=\"::vm.investor\"></contact-button><p>{{:: vm.investor.description }}</p><p class=\"links\" ng-show=\"::vm.investor.website || vm.investor.screenName\"><a ng-show=\"::vm.investor.website\" ng-href=\"{{ ::vm.investor.website }}\" target=\"_blank\"><img src=\"/assets/images/website.png\"> Website</a><divider ng-show=\"::vm.investor.screenName && vm.investor.website\"></divider><a ng-show=\"::vm.investor.screenName\" ng-href=\"https://twitter.com/{{ ::vm.investor.screenName }}\" target=\"_blank\"><img src=\"/assets/images/twitter.png\"> Twitter</a></p></div>");
$templateCache.put("app/components/addInvestorForm/addInvestorForm.html","<form ng-submit=\"vm.add($event)\" name=\"investorForm\" novalidate=\"\"><input type=\"text\" placeholder=\"Name\" name=\"name\" ng-model=\"vm.investor.name\" required=\"\"> <input type=\"email\" placeholder=\"Email\" ng-model=\"vm.investor.email\" required=\"\"> <input type=\"text\" placeholder=\"Location\" ng-model=\"vm.investor.location\" required=\"\"><dropdown-button text=\"\'Category: <strong>\' + vm.investor.category + \'</strong>\'\" pre-selection=\"vm.investor.category\"><dropdown-button-choice-list options=\"vm.categories\"></dropdown-button-choice-list></dropdown-button><input type=\"hidden\" ng-model=\"vm.investor.category\" dropdown-validator=\"\"> <input type=\"text\" placeholder=\"Twitter (optional)\" ng-model=\"vm.investor.twitter\"> <input type=\"text\" placeholder=\"Website (optional)\" ng-model=\"vm.investor.website\"> <input type=\"text\" placeholder=\"LinkedIn (optional)\" ng-model=\"vm.investor.linkedin\"> <textarea placeholder=\"Description: a short bio, information about the sectors invested in, likes & dislikes, anything else that might be useful for entrepreneurs to know!\" ng-model=\"vm.investor.description\"></textarea><section class=\"cta\"><span>Your submission will be sent to the Investor List elves for speedy approval</span> <button ng-disabled=\"investorForm.$invalid || vm.disabled\" class=\"raised-button -cta\" type=\"submit\">Submit</button></section></form>");
$templateCache.put("app/components/categoryFilter/categoryFilter.html","<dropdown-button text=\"vm.config.title + \': <strong>\' + vm.config.preSelection + \'</strong>\'\" state-watcher=\"vm.config\" pre-selection=\"vm.config.preSelection\"><dropdown-button-choice-list options=\"vm.config.options\"></dropdown-button-choice-list></dropdown-button>");
$templateCache.put("app/components/clearSearch/clearSearch.html","<span ng-click=\"vm.clear()\" ng-show=\"vm.active\">✕</span>");
$templateCache.put("app/components/contactButton/contactButton.html","<div ng-switch=\"vm.bestContact\"><a ng-href=\"mailto:{{ vm.investor.email }}\" target=\"_blank\" ng-switch-when=\"email\" class=\"raised-button -cta\">Contact</a> <a ng-href=\"https://twitter.com/{{ vm.investor.screenName }}\" target=\"_blank\" ng-switch-when=\"twitter\" class=\"raised-button -cta\">Contact via Twitter</a> <a ng-href=\"{{ vm.investor.linkedin }}\" target=\"_blank\" ng-switch-when=\"linkedin\" class=\"raised-button -cta\">Contact via LinkedIn</a> <a ng-switch-default=\"\" class=\"raised-button -disabled\" tooltip=\"\">No contact details yet</a></div>");
$templateCache.put("app/components/dropdownButton/dropdownButton.html","<div class=\"dropdown-button uk-button-dropdown uk-dropdown-close -direction-{{ vm.direction }}\" data-uk-dropdown=\"{boundary: \'dropdown-button\', mode:\'click\', pos: \'{{ vm.direction }}\'}\"><button class=\"trigger raised-button\"><span ng-bind-html=\"vm.dropdownLabel\"></span></button><div class=\"uk-dropdown\"><ul class=\"uk-nav uk-nav-dropdown list-items uk-dropdown-scrollable\" ng-transclude=\"\"></ul></div></div>");
$templateCache.put("app/components/inputSearchField/inputSearchField.html","<input type=\"text\" ng-model=\"vm.query\" placeholder=\"{{vm.placeholder}}\">");
$templateCache.put("app/components/investorCard/investorCard.html","<a class=\"pic\" ui-sref=\"home.list({ id: vm.investor.slug })\" ui-sref-opts=\"{ inherit: false, notify: false }\" ng-click=\"vm.launch()\" ng-style=\"vm.bg()\"></a><aside class=\"investor-info\"><a class=\"name\" ui-sref=\"home.list({ id: vm.investor.slug })\" ui-sref-opts=\"{ inherit: false, notify: false }\" ng-click=\"vm.launch()\">{{:: vm.investor.name }}</a><p>{{:: vm.investor.type }}<divider ng-if=\":: vm.investor.country.trim()\"></divider><img class=\"country-flag\" ng-if=\":: vm.investor.country.length && vm.flagCode\" ng-src=\"/assets/images/flags/{{ vm.flagCode }}.png\"> {{:: vm.investor.country | truncateCountries }}</p></aside>");
$templateCache.put("app/components/loadingSpinner/loadingSpinner.html","<div class=\"right\"><div class=\"border\"></div></div><div class=\"left\"><div class=\"border\"></div></div>");
$templateCache.put("app/components/locationFilter/locationFilter.html","<dropdown-button text=\"vm.config.title + \': <strong>\' + vm.config.preSelection + \'</strong>\'\" state-watcher=\"vm.config\" pre-selection=\"vm.config.preSelection\"><dropdown-button-choice-list options=\"vm.config.options\"></dropdown-button-choice-list></dropdown-button>");
$templateCache.put("app/components/navBar/navBar.html","<nav class=\"navbar uk-navbar\" headroom=\"\" tolerance=\"0\" offset=\"120\" classes=\'{ unpinned: \"-hidden\" }\'><container><ul class=\"uk-navbar-nav\"><a class=\"uk-navbar-brand\" href=\"/\"><img src=\"assets/images/logo.png\"></a></ul><div class=\"uk-navbar-flip\"><ul class=\"uk-navbar-nav\"><li class=\"uk-parent -direction-bottom-right\" data-uk-dropdown=\"{mode:\'click\', pos: \'bottom-right\'}\"></li><li><a ng-click=\"vm.about()\" href=\"\">About</a></li><li><a href=\"mailto:me@alexpeattie.com\">Contact</a></li></ul></div></container></nav>");
$templateCache.put("app/components/poweredBy/poweredBy.html","<p>Powered by:</p><a href=\"https://peg.co\" target=\"_blank\"><img src=\"https://peg.co/assets/peg-full-icon.png\"></a>");}]);